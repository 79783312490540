@import "../../components/_style_/_baseStyle.scss";

.requirementForm {
	margin-top: 2em;
	background-color: $white;
	border: 1px solid $border-grey;
	border-radius: $border-radius;

	:global {
		.control-label {
			color: lighten($grey, 10);
			font-weight: normal;
		}
	}

	h2, h3 {
		color: $grey;
		font-weight: normal;
	}
}

.requirementTitle {
	color: $white;
	background-color: $mainColor;
	padding: 25px 15px 25px 15px;
	margin-top: 0;
	margin-bottom: 0;
	border-top-left-radius: $border-radius - 1px;
	border-top-right-radius: $border-radius - 1px;
	font-weight: normal;
}

.buttonList {
	padding: 2em 0 4em 0;
	margin: 15px;
	border-top: 0.1em solid $border-grey;

	button {
		border-radius: 1.5em;
	}
}

.noActionNotification {
	padding: 2em 0 2em 0;
	color: $grey;

	p {
		margin-top: 2em;
		font-weight: 200;
		font-size: 1.25em;
		white-space: pre-wrap;
	}
}
