@import "../../components/_style_/_baseStyle.scss";

.errorIndicator {
	color: $white;
	background-color: $red;
	border-radius: 0.7em;
	min-width: 1.4em;
	height: 1.4em;
	font-size: 0.9em;
	display: block;
	position: absolute;
	right: -0.7em;
	top: -0.7em;
	text-align: center;
	line-height: 1.42857143; // the default 'body' value, also see https://css-tricks.com/almanac/properties/l/line-height/#article-header-id-0
	padding: 0 0.3em 0 0.3em;
}
