@import "../../components/_style_/_baseStyle.scss";

.Section {
	text-align: left;
	margin: auto;

	small {
		padding-left: 1em;
		color: $red;
	}
}

.error {
	background-color: rgba($red, .1);
	border: 1px solid var(--bs-danger);
	border-radius: var(--bs-border-radius);
}

.tooltip {
	font-size: 0.65em;
}
