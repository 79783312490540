.loading{
	width: 100%;
	height: 80%;
	text-align: center;
}

.loadingImg{
	font-size: 5em;
	margin: 2em 0 1em 0;
	animation: rotation 2s infinite linear;
}

@keyframes rotation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}
