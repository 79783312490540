@import "../../components/_style_/_baseStyle.scss";

.saving {
	font-size: 1em;
	animation: rotation 2s infinite linear;
	color: $grey;
}

.addButton {
	font-size: 1em;
	color: $grey;
	cursor: pointer;
}

.title {
	small {
		color: $red;
		margin-left: 1em;
	}
}

.error {
	color: $red;
	background-color: rgba($red, .1);
}

@keyframes rotation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}

.collapsibleDG {
	border: 1px solid $border-grey;
	border-radius: $border-radius;
	margin-bottom: 0.5em;
}
