.sessionExpiredAlert {
	margin-top: 1em;

	.text {
		margin-bottom: 1em;
	}

	.buttons {
		text-align: right;
	}
}
