@import "../../components/_style_/_baseStyle.scss";

.row {
	background-color: $mainColor;
	display: flex;
	padding: 0 1em 1em 1em;
}

$activeColor: lighten($mainColor, 80);
$inactiveColor: lighten($mainColor, 20);
$activeError: $red;
$inactiveError: darken($red, 20);

%step-shared {
	flex: 1;
	min-width: 5em;
	max-width: 10em;
	background-color: $mainColor;
	text-align: center;
	font-size: 1.2em;
	margin-bottom: 1em;
	position: relative;
	padding-bottom: 1.2em;
	border-bottom: 0.1em solid;

	.numberBullet {
		width: 1.6em;
		height: 1.6em;
		border-radius: 50%;
		padding-top: 0.15em;
		display: block;
		position: absolute;
		bottom: -0.8em;
		margin-left: auto;
		margin-right: auto;
		left: 0;
		right: 0;
		color: $mainColor;
	}

	.numberCircle {
		font: 32px Arial, sans-serif;

		width: 2em;
		height: 2em;

		background: #fff;
		border: 0.1em solid #666;
		color: #666;
		text-align: center;
		border-radius: 50%;

		line-height: 2em;
		box-sizing: content-box;
	}
}

.active {
	@extend %step-shared;
	color: $activeColor;
	border-bottom-color: $activeColor;

	.numberBullet {
		background-color: $activeColor;
	}

}

.inactive {
	@extend %step-shared;
	color: $inactiveColor;
	border-bottom-color: $inactiveColor;
	cursor: pointer;

	.numberBullet {
		background-color: $inactiveColor;
	}

}

