@import "../../components/_style_/_baseStyle.scss";

.dataGroupContainer{
	padding: 0;
}

.DataGroup {
	margin: 0;
	&:hover {
		background-color: $border-grey;
	}

	.expandCol {
		padding: 1em 0 1em 0;
		position: relative;
		display: flex;
		flex-direction: row;
		line-height: 2em;
		cursor: pointer;

		.dataGroupName {
			flex: 1;
			font-weight: bold;

			svg {
				margin-right: 1em;
				height: 100%;
				font-size: 1.2em;
			}
		}

		.deleteButton {
			flex: 1;
			text-align: right;
		}
	}
}
