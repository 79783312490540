@import "../../_style_/_baseStyle.scss";

.selectAll {
	font-size: 0.7em;
	color: lighten($grey, 20%);
}

.selectAll > span {
	text-decoration: underline;
	margin: 0 0 0 1em;
	visibility: hidden;
}

.title:hover > span > span {
	visibility: visible;
	cursor: pointer;
}

.value {
	cursor: pointer;
}
