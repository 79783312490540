.buttonsArea {
	margin: 10px 0 10px 0;
	display: flex;
	justify-content: space-between;
}

.buttonLeft {
	flex: 1;
	text-align: left;
}

.buttonRight {
	flex: 1;
	text-align: right;
}
