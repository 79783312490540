@import "../../_style_/baseStyle";

.radioSpanH {
	margin-left: 8px;
	display: inline-block;
}

.radioSpanV {
	margin-left: 8px;
	display: flex;
}
