@import "../../components/_style_/_baseStyle.scss";

.saving {
	font-size: 1em;
	animation: rotation 2s infinite linear;
	color: $grey;
}

@keyframes rotation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}

.text {
	padding-left: 0.7em;
}
