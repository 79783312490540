@import "../_style_/baseStyle";

.hidden {
	display: none;
}

.dropZone {
	background-color: $light-grey;
	border-radius: $border-radius;
	border: 1px solid $border-grey;
	padding: 0.5em;

	button {
		display: inline;
		text-decoration: underline;
		border: none;
		background-color: inherit;
		padding: 0;
	}

}

.dropLabel {
	margin-bottom: 0.5em;
}

.fileItemList {
	padding-left: 0;
}

.errorList {
	padding-left: 0;

	li {
		display: block;
		font-size: 1em;
		padding: 0.5em;
		margin-bottom: 20px;
		border-radius: 4px;
		background-color: #f2dede;
		border: 1px solid #ebccd1;
		color: #a94442;
	}
}

