@import "../../_style_/baseStyle.scss";

input[type="checkbox"] {
	margin-right: 7px;
}

.checkboxSpanH {
	margin-left: 8px;
	display: inline-block;
}

.checkboxSpanV {
	margin-left: 8px;
	display: flex;
}
