.linkRequestor {
	text-align: center;

	input, button {
		margin-left: 1em;
	}

	button {
		vertical-align: top;
	}

	form {
		margin-bottom: 1em;
	}
}
