@import "../../components/_style_/_baseStyle.scss";

.welcomeArea {
	color: $grey;
	background-color: $white;
	border: 1px solid $border-grey;
	border-radius: $border-radius;
	padding: 3em 5em 5em 5em;

	h1 {
		font-size: 5em;
	}

	p {
		font-weight: 200;
		font-size: 1.5em;
		margin-bottom: 3em;
	}
}

.welcomeTitle {
	margin-top: 15px;
}

.startButton {
	background-color: $mainColor;
	color: white;
	border-radius: 1.5em;
	outline: none;
}
