@import "../../components/_style_/_baseStyle.scss";


.KycChecklistTitle {
	padding: 0.7em 0 0.7em 0.7em;
	margin: 0 0.3em 0 0.3em;
	border-bottom: 1px solid $border-grey;
}

.KycChecklist {
	padding-bottom: 3em;
	display: flex;
	flex-direction: column;

	.KycChecklistLink {
		margin: 0 1em 0 1em;
		padding: 0.5em 0 0.5em 0;
	}
}
