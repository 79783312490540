@import "./components/_style_/_baseStyle.scss";
// Ingenico customization of Bootstrap (thanks to https://bootstrap.build/app)
$font-size-base: 0.85rem;
$blue: $mainColor;
$body-bg: $light-grey;

@import '~bootstrap/scss/bootstrap.scss';


.btn-primary {
	color: $white
}

.form-control, .form-check-input {
	background-color: $white;
}

body {
	margin: 0;
	padding: 0;
}
