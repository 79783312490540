@import "../../components/_style_/_baseStyle.scss";

.title {
	padding: 0.7em 0 0.7em 0.7em;
	margin: 0 0.3em 0 0.3em;
	border-bottom: 1px solid $border-grey;
	font-weight: normal;
}

.Flow {
	padding-bottom: 3em;

	.FlowSteps {
		margin: 0 0.3em 0 0.3em;
		padding: 0.5em 0 0.5em 0;
		border-bottom: 1px solid $border-grey;

		.FlowStep {
			width: 100%;
			padding: 0.7em 0 0.7em 0;
			display: flex;

			.icon {
				flex: 1;

				.circle {
					font-size: 2em;
					padding: 0.5em;
					width: 2em;
					height: 2em;
					display: inline-block;
					vertical-align: middle;
				}
			}

			.Name {
				flex: 9;
				display: flex;
				justify-content: center;
				flex-direction: column;

				span {
					vertical-align: sub;
				}
			}
		}

		.done {
			.icon {
				.circle {
					color: $successColor;
				}
			}

			color: $grey
		}

		.current {
			.icon {
				.circle {
					color: $mainColor;
				}
			}

			color: $mainColor
		}

		.todo {
			.icon {
				.circle {
					color: lighten($grey, 50%);
				}
			}

			color: $grey

		}
	}

}
