@import "../../_style_/baseStyle";

.labelWithButtons {
	width: 100%;

	button {
		float: right;
	}
}

.plusButton {
	color: #3c763d;
	margin-left: -4px
}

.minusButton {
	color: #a94442;
	margin-left: -4px
}
