@import "../_style_/baseStyle";

.fileItem {
	display: flex;
	width: 100%;
	margin-bottom: 0.2em;
	padding: 0.4em;
	flex-direction: column;

	.firstLine {
		display: flex;

		p {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			flex: 1;
			margin-bottom: 0;
		}

		.removeBtn {
			flex: 1;
			max-width: 1em;
			margin: 0 0.2em 0 0.2em;
			cursor: pointer;
		}
	}

	.secondLine {
		font-size: 0.75em;

		p {
			margin-bottom: 0;
		}

	}
}
