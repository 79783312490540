$red: #EF4036;
$turquoise: #277777;

$white: #ffffff;
$light-grey: #EFEFEF;
$border-grey: #E3E7EA;
$grey: #5B5B5B;

$mainColor: #277777;
$successColor: #357740;

$border-radius: 5px;

$backInput: $white;

$invalid-border: 1px solid transparent;
$invalid-border-radius: 5px;
$invalid-border-image-moz: -moz-linear-gradient(top, $red 0%, darken($red, 20) 100%);
$invalid-border-image-webkit: -webkit-linear-gradient(top, $red 0%, darken($red, 20) 100%);
$invalid-border-image: linear-gradient(to right, darken($red, 20) 0%, $red 100%) 1;

$error-message-color: darken($red, 20);

button, button:hover, button:active {
	outline: none !important;
	box-shadow: none;
}

.form-control.is-valid {
	background-position: center right calc(0.375em + 0.5rem) !important;
}
