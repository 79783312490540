@import "../../components/_style_/_baseStyle.scss";

body, .App {
	background-color: $light-grey;
}

.AppLogo {
	float: left;
	height: 50px;
	margin: -14px 0 0 5px;
}

.AppHeader {
	background-image: linear-gradient(to right, $mainColor, $turquoise);
	height: 70px;
	color: $grey;
	padding: 15px;
	text-align: center;
	position: relative;
}

.AppFooter {
	text-align: center;
	color: lighten($grey,30);
	margin-top: 50px;
	margin-bottom: 15px;
	a {
		color: lighten($mainColor,30);
	}
	a:hover q{
		color: lighten($mainColor,30);
		text-decoration: underline;
	}
}

.AppTitle {
	padding-top: 10px;
	margin-top: 0;
	font-size: 1.5em;
}

.AppLangSwitcher {
	text-align: right;
}

.title {
	margin-left: -150px;
}

.AppIntro {
	font-size: large;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
